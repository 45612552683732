import clsx from "clsx";
import { useEffect, useState } from "react";

const Logo = ({ fill = "#ffffff" }) => {
  const [cursorVisible, setCursorVisible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setCursorVisible((oldValue) => !oldValue);
    }, 1000);
    return () => clearInterval(interval);
  }, [cursorVisible]);

  return (
    <svg
      viewBox="0 0 50 50"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx("block h-full")}
    >
      <g id="ycn-logo">
        <polygon id="base" points="0 50 50 50 50 0 0 0" />
        {cursorVisible && (
          <polygon
            id="cursor"
            fill={fill}
            points="28.6434628 46.6885343 43.1963218 46.6885343 43.1963218 43.9015463 28.6434628 43.9015463"
          />
        )}
        <polygon
          id="n"
          fill={fill}
          points="6.44735199 27.0804716 11.7098923 27.0804716 17.6934819 34.5052986 17.6934819 27.0804716 23.2681038 27.0804716 23.2681038 43.9015463 18.270063 43.9015463 12.0222968 36.139555 12.0222968 43.9015463 6.44735199 43.9015463"
        />
        <path
          d="M32.4161073,13.4924924 C32.4161073,15.5293651 34.0674803,17.1814918 36.1047836,17.1814918 C37.5578626,17.1814918 38.811033,16.3390116 39.4123739,15.1174908 L44.4259164,15.9975413 C43.349725,19.5758749 40.0333074,22.1838385 36.1047836,22.1838385 C31.3046063,22.1838385 27.4137604,18.2927775 27.4137604,13.4924924 C27.4137604,8.69242263 31.3046063,4.80125393 36.1047836,4.80125393 C40.0333074,4.80125393 43.349725,7.40921755 44.4259164,10.9872282 L39.4123739,11.8671711 C38.811033,10.6461885 37.5578626,9.80360073 36.1047836,9.80360073 C34.0674803,9.80360073 32.4161073,11.4552967 32.4161073,13.4924924 Z"
          id="c"
          fill={fill}
        />
        <polygon
          id="y"
          fill={fill}
          points="24.012944 4.98049356 17.6691528 15.7693203 17.6691528 21.8005994 12.0461954 21.8005994 12.0461954 15.8417696 5.70240408 4.98049356 11.9983982 4.98049356 14.8817341 10.5072105 17.7893994 4.98049356"
        />
      </g>
    </svg>
  );
};

export default Logo;
