import state from "@/state";
import clsx from "clsx";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import { Search } from "react-feather";

const SearchForm = ({ initialQuery = "", open = true, close = () => null }) => {
  const router = useRouter();

  const [query, setQuery] = useState(initialQuery);

  const inputRef = useRef();

  const handleClose = () => {
    // Close the popover
    close();

    // Set the global state to the closed state of the menu
    state.search.isOpen = false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (query === "") return;

    // Use next router to navigate to the search page with the query
    router.push({
      pathname: "/search",
      query: { query },
    });

    handleClose();
  };

  useEffect(() => {
    if (open) inputRef.current.focus();
  }, [open]);

  return (
    <form
      onSubmit={handleSubmit}
      className={clsx("SearchForm", "flex justify-center")}
    >
      <div className={clsx("flex w-full max-w-xl space-x-4")}>
        <div className={clsx("relative w-full")}>
          <div
            className={clsx(
              "SearchForm__icon",
              "absolute top-0 left-0 flex h-full w-12 items-center justify-center"
            )}
          >
            <Search className={clsx("w-5")} strokeWidth={2} />
          </div>
          <input
            ref={inputRef}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            className={clsx(
              "SearchForm__input",
              "form-input block w-full rounded-full border-transparent px-6 py-3 pl-11 shadow-sm focus:border-transparent focus:ring-0"
            )}
            placeholder="Search resources, events, and more"
            required
          />
        </div>
        <button
          type="submit"
          className={clsx(
            "hidden rounded-full border-transparent bg-sky-500 px-6 font-medium text-white shadow-sm transition duration-300 ease-out hover:bg-sky-600 lg:block"
          )}
        >
          Search
        </button>
      </div>
    </form>
  );
};

export default SearchForm;
