import clsx from "clsx";
import style from "components/FooterNavigation.module.scss";
import { getHref } from "lib/helpers";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

const FooterNavigation = ({ primaryNavigation }) => {
  const router = useRouter();

  if (!primaryNavigation) return null;

  return (
    <ul className={style.root}>
      {primaryNavigation.map((item) => {
        const { element } = item;

        if (!element) {
          console.log(`Element doesn't exist for nav item`, item);
          return null;
        }

        const href = getHref(element);

        const isActive = href === router.asPath;

        const itemClassName = clsx(style.item, {
          [style.item___isActive]: isActive,
        });

        return (
          <li key={item.id} className={itemClassName}>
            <Link href={href} className={style.link}>
              <div className={style.label}>{item.title}</div>
            </Link>
          </li>
        );
      })}

      <li>
        <a href="#" className="iubenda-cs-preferences-link">
          Your Privacy Choices
        </a>
      </li>
    </ul>
  );
};

export default FooterNavigation;
