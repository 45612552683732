import clsx from "clsx";
import { useSession } from "next-auth/react";
import Link from "next/link";
import { useRouter } from "next/router";

const SecondaryNavigation = ({
  items = [],
  className = "",
  itemClassName = "",
  activeItemClassName = "",
  onItemClick = () => null,
  iconClassName = "",
}: {
  items: {
    key: string;
    href: string;
    icon?: any;
    label: string;
  }[];
  className?: string;
  itemClassName?: string;
  activeItemClassName?: string;
  onItemClick?: () => void;
  iconClassName?: string;
}) => {
  const { data: session } = useSession();

  return (
    <ul className={clsx("AccountNavigiation", className, "group")}>
      <>
        {items.map((item) => {
          return (
            <_NavItemLink
              key={item.key}
              href={item.href}
              icon={item.icon}
              onClick={onItemClick}
              className={itemClassName}
              activeClassName={activeItemClassName}
              iconClassName={iconClassName}
            >
              {item.label}
            </_NavItemLink>
          );
        })}
      </>
    </ul>
  );
};

const _NavItemLink = ({
  href,
  children,
  icon,
  onClick,
  className,
  activeClassName,
  iconClassName,
}) => {
  const router = useRouter();
  const Icon = icon || null;

  return (
    <li>
      <Link
        className={clsx(
          "leading-none w-full flex items-center space-x-2",
          className,
          {
            [activeClassName]: router.pathname === href,
          },
        )}
        onClick={onClick}
        scroll={false}
        href={href}
      >
        {Icon && (
          <div>
            <Icon className={clsx(iconClassName)} />
          </div>
        )}
        <div>{children}</div>
      </Link>
    </li>
  );
};

export default SecondaryNavigation;
