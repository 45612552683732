import clsx from "clsx";
import { RotateCw } from "react-feather";

const Spinner = ({ size = "sm" }: { size: "xs" | "sm" | "md" | "lg" }) => {
  return (
    <div>
      <RotateCw
        className={clsx("animate-spin", {
          "h-3 w-3 stroke-[1.5]": size === "xs",
          "h-4 w-4 stroke-2": size === "sm",
          "h-5 w-5 stroke-2": size === "md",
          "h-6 w-6 stroke-2": size === "lg",
        })}
      />
    </div>
  );
};

export default Spinner;
