import { useCallback, useEffect } from "react";

interface TrackData {
  type?: string | null;
  id?: string;
  title?: string;
  uri?: string;
  url?: string;
}

export const track = async (event: string, data: TrackData = {}) => {
  const trackingData = {
    ...data,
    url: typeof window !== "undefined" ? window.location.href : undefined,
  };

  if (process.env.NEXT_PUBLIC_VERCEL_ENV !== "production") {
    console.log("-- Track [dev]", event);
    return;
  }

  try {
    const res = await fetch("/api/track", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ event, data: trackingData }),
    });
    const json = await res.json();
    return { status: "success", data: json };
  } catch (error) {
    return { status: "error", error };
  }
};

export const useTrack = (
  event: string | undefined,
  type?: string,
  id?: string,
  title?: string,
  uri?: string,
) => {
  const trackEvent = useCallback(async () => {
    // Avoid tracking if event is undefined
    if (!event) return;

    await track(event, {
      type,
      id,
      title,
      uri,
    });
  }, [event, type, id, title, uri]);

  // Track event
  useEffect(() => {
    trackEvent();
  }, [trackEvent]);

  return track;
};

export default useTrack;
