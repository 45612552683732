import state from "@/state";
import { useEffect } from "react";
import { useScrollData } from "scroll-data-hook";

const TOP_THRESHOLD = 30;

const useScrollSpy = () => {
  const { scrolling, direction, position } = useScrollData();

  useEffect(() => {
    if (!scrolling) return;

    if (position.y > TOP_THRESHOLD && direction.y === "down") {
      state.header.isPinned = false;
    }
    if (position.y < TOP_THRESHOLD || direction.y === "up") {
      state.header.isPinned = true;
    }

    state.header.isTop = position.y < TOP_THRESHOLD;
  }, [position.y, scrolling, direction.y]);

  return;
};

export default useScrollSpy;
