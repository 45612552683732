import state from "@/state";
import clsx from "clsx";
import Image from "next/legacy/image";
import { useSnapshot } from "valtio";

const SiteLogo = ({ darkLogo, lightLogo, enlargedHeight = false }) => {
  const stateSnapshot = useSnapshot(state);

  return (
    <div
      className={clsx("w-full relative", {
        "h-8": !enlargedHeight,
        "h-12 lg:h-20": enlargedHeight,
      })}
    >
      {stateSnapshot.header.theme === "light" && (
        <Image
          src={lightLogo?.url || darkLogo.url}
          layout="fill"
          objectFit="contain"
          objectPosition="left center"
          alt={lightLogo?.title || darkLogo.title}
        />
      )}
      {stateSnapshot.header.theme === "dark" && (
        <Image
          src={darkLogo?.url || lightLogo?.url}
          layout="fill"
          objectFit="contain"
          objectPosition="left center"
          alt={darkLogo?.title || lightLogo?.title}
        />
      )}
    </div>
  );
};

export default SiteLogo;
