import Chroma from "chroma-js";
import { FastAverageColor } from "fast-average-color";
import ReactHtmlParser from "react-html-parser";
import { removeWidows } from "string-remove-widows";

export const widont = (string = null) => {
  if (!string) return;

  return ReactHtmlParser(removeWidows(string).res);
};

export const getHref = (entry) => {
  if (!entry) return "";

  const handle = entry.sectionHandle || entry.groupHandle;

  switch (handle) {
    case "about":
      return `/about`;
    case "articleIndex":
      return `/articles`;
    case "articles":
      return `/articles/${entry.slug}`;
    case "eventIndex":
      return `/events`;
    case "events":
      return `/events/${entry.slug}`;
    case "newUserLanding":
      return `/welcome`;
    case "pages":
      return `/${entry.uri}`;
    case "resourceCollections":
      return `/resources/collections/${entry.slug}`;
    case "resourceIndex":
      return `/resources`;
    case "resources":
      return `/resources/${entry.slug}`;
    case "userLanding":
      return `/you`;
    case "signUp":
      return `/auth/signup`;
    case "signIn":
      return `/auth/signin`;
    case "pathwayIndex":
      return `/pathways`;
    case "pathways":
      return `/pathways/${entry.slug}`;
    case "developmentIndex":
      return `/development`;
    case "development":
      return `/development/${entry.slug}`;
    default:
      return `/`;
  }
};

export const getColor = async (src) => {
  const fac = new FastAverageColor();

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(fac.getColor(img));
    img.onerror = reject;
    img.crossOrigin = "Anonymous";
    img.src = `${src}?cache=0`;
  });
};

export const isColorDark = (hex) => {
  if (!hex) return false;

  const color = Chroma(hex);
  return color.get("lab.l") < 70;
};

export const isImageDark = async (src) => {
  const color = await getColor(src);

  const hexColor = await color.hex;

  const isDark = isColorDark(hexColor);

  return isDark;
};

export const isRestrictedToOrganisations = (entry) => {
  // Is the entry restricted to orgs?
  return entry.organisations.length > 0;
};

export const isRestrictedToUsers = (entry) => {
  // Is the entry restricted to users?
  return entry.users.length > 0;
};

export const userCanViewEntry = (user, entry) => {
  // If no orgs or users are specified, return true
  if (entry.organisations?.length === 0 && entry.users?.length === 0)
    return true;

  // Gather the ids of users this entry is restricted to
  const userIds = entry.users.map((u) => u.id);

  // Gather the ids of organisations this entry is restricted to
  const organisationIds = entry.organisations.map((co) => co.id);

  // Is it restricted
  const isRestricted =
    isRestrictedToOrganisations(entry) || isRestrictedToUsers(entry);

  // If no user, return any entry that isn't restricted
  if (!user)
    return !isRestrictedToOrganisations(entry) && !isRestrictedToUsers(entry);

  // Otherwise, make sure the user belongs to restricted organisations
  const { organisation } = user;

  const canUserOrganisationViewResource =
    !!organisation && organisationIds.includes(organisation.id);

  const canUserViewResource = userIds.includes(user.id.toString()); // compare strings

  return (
    !isRestricted ||
    (isRestricted &&
      isRestrictedToOrganisations(entry) &&
      canUserOrganisationViewResource) ||
    (isRestricted && isRestrictedToUsers(entry) && canUserViewResource)
  );
};
