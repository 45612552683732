import state from "@/state";
import clsx from "clsx";
import { Menu } from "components/Icons";
import React from "react";
import { X } from "react-feather";
import { useSnapshot } from "valtio";

const MenuToggle = () => {
  const stateSnapshot = useSnapshot(state);

  const handleClick = () => {
    state.menu.isOpen = !stateSnapshot.menu.isOpen;
  };

  // TODO: remove important declarations when all buttons are converted to Tailwind

  return (
    <button
      className={clsx(
        "flex h-10 w-10 items-center justify-center rounded-full bg-gray-900 text-white hover:bg-sky-500"
      )}
      onClick={handleClick}
      type="button"
    >
      {stateSnapshot.menu.isOpen ? (
        <X className={clsx("h-6 w-6 stroke-[1.5]")} />
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth={1.5}
          strokeLinecap="butt"
          strokeLinejoin="round"
          className={clsx("h-6 w-6")}
        >
          <line x1="6" y1="6" x2="21" y2="6" stroke="currentColor" />
          <line x1="3" y1="12" x2="21" y2="12" stroke="currentColor" />
          <line x1="9" y1="18" x2="21" y2="18" stroke="currentColor" />
        </svg>
      )}
    </button>
  );
};

export default MenuToggle;
