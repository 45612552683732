import clsx from "clsx";
import { getHref } from "lib/helpers";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

const PrimaryNavigation = ({ primaryNavigation, exclude = [] }) => {
  const router = useRouter();

  return (
    <div className={clsx("PrimaryNavigation", "flex items-center font-medium")}>
      <ul
        className={clsx(
          "PrimaryNavigation__items",
          "flex items-center space-x-4 2xl:space-x-5",
        )}
      >
        {primaryNavigation.map((item) => {
          const { element, url } = item;

          if (!element && !url) {
            return null;
          }

          const isHighlighted = item.classes?.includes("highlighted");

          // TODO: This is a hack to remove the "Set up your Login" link from the nav
          if (item.title === "Set up your Login") return null;

          // TODO: this is a hack to remove excludeFromHeader items from the header
          if (exclude.includes(item.title)) return null;

          // If we have an element
          if (!!element) {
            const href = getHref(element);

            const isActive = router.asPath.includes(href);

            return (
              <li key={item.id} className={clsx("PrimaryNavigation__item")}>
                <Link
                  href={href}
                  className={clsx(
                    "PrimaryNavigation__link",
                    "flex h-10 items-center transition duration-300 ease-out leading-none",
                    {
                      "rounded-full bg-gray-900 px-5 text-white hover:bg-sky-500":
                        isHighlighted,
                      "hover:opacity-75": !isHighlighted,
                      "opacity-50": isActive && !isHighlighted,
                    },
                  )}
                >
                  <div className={clsx("whitespace-nowrap")}>{item.title}</div>
                </Link>
              </li>
            );
          }

          // If we just have a URL...
          if (!!url) {
            return (
              <li key={item.id} className={clsx("")}>
                <a className={clsx("")} href={url}>
                  <div className={clsx("whitespace-nowrap")}>{item.title}</div>
                </a>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default PrimaryNavigation;
