const isYcnAdmin = (email: string) => {
  // If the current user's email address ends in `@ycn.org` they are granted access
  if (email?.endsWith("@ycn.org")) {
    return true;
  }

  // Let's let howells in too
  if (email?.endsWith("@howellsstudio.com")) {
    return true;
  }

  return false;
};

export default isYcnAdmin;
