import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import clsx from "clsx";
import { ES, FR, GB, IT } from "country-flag-icons/react/3x2";
import Link from "next/link";
import { useRouter } from "next/router";
import { ChevronDown } from "react-feather";

const regions = [
  {
    key: "en",
    label: "English",
    locale: "",
    flag: GB,
  },
  {
    key: "es",
    label: "Spanish",
    locale: "es",
    flag: ES,
  },
  {
    key: "fr",
    label: "French",
    locale: "fr",
    flag: FR,
  },
  {
    key: "it",
    label: "Italian",
    locale: "it",
    flag: IT,
  },
];

const SiteSelector = () => {
  const router = useRouter();
  const currentSite = router.locale;

  const regionsToShow = regions.filter(
    (region) => (region.locale || "en") !== (currentSite || "en"),
  );

  const currentRegion = regions.find(
    (region) => (region.locale || "en") === (currentSite || "en"),
  );

  const CurrentFlag = currentRegion?.flag;

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger
        className={clsx(
          "bg-gray-950 hover:bg-sky-500 hover:text-white flex h-10 px-3.5 items-center justify-center rounded-full focus:border-transparent focus:outline-none space-x-2 text-white",
        )}
        aria-label={`Change language. Current language: ${currentRegion?.label}`}
      >
        <div className={clsx("w-4 flex items-center")} aria-hidden="true">
          {CurrentFlag && <CurrentFlag className={clsx("w-full")} />}
        </div>

        <ChevronDown className={clsx("size-4")} aria-hidden="true" />
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          sideOffset={5}
          className={clsx(
            "SiteSelector",
            "z-40 w-32 max-w-4xl overflow-hidden rounded-lg bg-gray-950 text-white shadow-sm ring-1 ring-gray-950/5",
          )}
        >
          {regionsToShow.map((region) => {
            const Flag = region.flag;

            return (
              <DropdownMenu.Item key={region.key} asChild>
                <Link
                  href={router.asPath}
                  locale={region.locale || "en"}
                  className={clsx(
                    "flex items-center h-10 px-3.5 space-x-3 focus-visible:outline-none focus-visible:bg-sky-500 hover:bg-gray-800",
                  )}
                  lang={region.locale || "en"}
                >
                  <div
                    className={clsx("w-4 flex items-center relative top-[1px]")}
                    aria-hidden="true"
                  >
                    <Flag className={clsx("w-full")} />
                  </div>
                  <div>{region.label}</div>
                </Link>
              </DropdownMenu.Item>
            );
          })}

          <DropdownMenu.Arrow />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default SiteSelector;
