import clsx from "clsx";
import FooterNavigation from "components/FooterNavigation";
import SocialLinks from "components/SocialLinks";
import React from "react";

const Footer = ({
  primaryNavigation,
  hideYcnBranding = false,
}: {
  primaryNavigation: any;
  hideYcnBranding?: boolean;
}) => {
  return (
    <footer
      className={clsx(
        "Footer",
        "flex w-full bg-gray-900 py-12 text-white md:py-24",
      )}
    >
      <div className={clsx("Footer__container", "container")}>
        <div className={clsx("space-y-6")}>
          {!hideYcnBranding && (
            <div className={clsx("Footer__header", "")}>
              <div className={clsx("Footer__statement", "heading-2xl--light")}>
                Can we help?
              </div>
            </div>
          )}
          <div
            className={clsx(
              "Footer__columns",
              "grid grid-cols-12 gap-y-6 sm:gap-x-6",
            )}
          >
            {!hideYcnBranding && (
              <div
                className={clsx(
                  "Footer__main",
                  "heading-xl--light col-span-12 md:col-span-5",
                )}
              >
                Email us at{" "}
                <a href="mailto:hello@ycn.org" className={clsx("text-sky-500")}>
                  hello@ycn.org
                </a>
                <br />
                or call{" "}
                <a href="tel:+442070332140" className={clsx("text-sky-500")}>
                  +44 20 7033 2140
                </a>
              </div>
            )}
            <div
              className={clsx(
                "Footer__navigation",
                "col-span-12 border-white/20 md:col-span-5",
                {
                  "md:border-l md:pl-16": !hideYcnBranding,
                },
              )}
            >
              <FooterNavigation primaryNavigation={primaryNavigation} />
            </div>
            {!hideYcnBranding && (
              <div
                className={clsx(
                  "Footer__socialLinks",
                  "col-span-12 flex md:col-span-2 md:justify-end",
                )}
              >
                <SocialLinks />
              </div>
            )}
          </div>
        </div>
        {!hideYcnBranding && (
          <div
            className={clsx(
              "Footer__base",
              "grid grid-cols-12 gap-y-2 pt-12 sm:gap-x-6",
            )}
          >
            <div className={clsx("col-span-12 md:col-span-6")}>
              <span className={clsx("font-medium")}>You Can Now</span> Since
              2001
            </div>
            <div
              className={clsx("col-span-12 flex md:col-span-6 md:justify-end")}
            >
              <a
                href="https://www.howellsstudio.com"
                target="_blank"
                rel="noreferrer"
              >
                Site by Howells—Studio
              </a>
            </div>
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
