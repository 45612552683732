export const easeInCubic = [0.32, 0, 0.67, 0];
export const easeInOutCubic = [0.645, 0.045, 0.355, 1];
export const easeInOutQuint = [0.83, 0, 0.17, 1];
export const easeInQuint = [0.64, 0, 0.78, 0];
export const easeOutCubic = [0.33, 1, 0.68, 1];
export const easeOutQuint = [0.22, 1, 0.36, 1];

export const transitionDefaults = { ease: easeOutQuint, duration: 0.5 };

export const GUTTER_WIDTH = 30;

export const SEARCH_HEIGHT = {
  desktop: GUTTER_WIDTH * 9,
  mobile: GUTTER_WIDTH * 5,
};

export const MENU_WIDTH = {
  desktop: 500,
};

export const defaultRootVariants = {
  initial: {},
  animate: {},
  exit: {},
};

export const defaultImageVariants = {
  initial: {
    scale: 1.025,
    opacity: 0,
    transition: { duration: 1, ease: easeOutQuint },
  },
  animate: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 1,
      ease: easeOutQuint,
    },
  },
  exit: {
    scale: 1.025,
    opacity: 0,
    transition: { duration: 1, ease: easeInCubic },
  },
};

export const defaultContainerVariants = {
  initial: {
    transition: {
      staggerChildren: 0.125,
    },
  },
  animate: {
    transition: {
      delayChildren: 0.5,
      staggerChildren: 0.125,
    },
  },
  exit: {
    transition: {
      staggerChildren: 0.125,
    },
  },
};

export const defaultChildVariants = {
  initial: (options) => {
    const direction = options?.direction || 1;
    const distance = options?.distance !== undefined ? options?.distance : 20;

    return {
      x: direction === 1 ? distance : distance * -1,
      opacity: 0,
      transition: { duration: 1, ease: easeOutQuint },
    };
  },
  animate: (options) => {
    return {
      x: 0,
      opacity: 1,
      transition: { duration: 1, ease: easeOutQuint },
    };
  },
  exit: (options) => {
    const direction = options?.direction || 1;
    const distance = options?.distance !== undefined ? options?.distance : 20;

    return {
      x: direction === 1 ? distance * -1 : distance,
      opacity: 0,
      transition: { duration: 1, ease: easeInCubic },
    };
  },
};
