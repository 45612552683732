import SecondaryNavigation from "@/components/SecondaryNavigation";
import Spinner from "@/components/Spinner";
import useTrack from "@/hooks/useTrack";
import accountNavigationItems from "@/lib/accountNavigationItems";
import getSiteUrl from "@/lib/getSiteUrl";
import { Popover, Transition } from "@headlessui/react";
import clsx from "clsx";
import { useSession } from "next-auth/react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { User, X } from "react-feather";

const AuthMenuPopover = () => {
  const track = useTrack();

  const [isNavigating, setIsNavigating] = useState(false);

  const router = useRouter();

  const { data: session, status } = useSession();

  const href = session ? "/account" : "/auth/signin";

  const itemClassName = clsx("px-4 py-1.5 hover:bg-gray-800 leading-0 flex");

  const handleClick = (ev) => {
    if (!session) {
      setIsNavigating(true);
      router.push("/auth/signin");
    }
  };

  const organisation = session?.user?.organisation;

  return (
    <Popover className="relative">
      {({ open, close }) => {
        const Icon = open ? X : User;

        return (
          <>
            <Popover.Button
              className={clsx(
                "flex h-10 w-10 items-center justify-center rounded-full focus:border-transparent focus:outline-none focus:ring-0",
                {
                  "bg-gray-900 text-white hover:bg-sky-500 hover:text-white":
                    !open,
                  "bg-sky-500 text-white ": open,
                },
              )}
              onClick={handleClick}
            >
              {!isNavigating && <Icon className={clsx("stroke-1.5 h-5 w-5")} />}
              {isNavigating && <Spinner className={clsx("h-5 w-5")} />}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={clsx(
                  "absolute right-0 z-10 w-48 max-w-4xl translate-y-2 overflow-hidden rounded-lg bg-gray-900 text-white shadow-sm ring-1 ring-gray-900/5 md:col-span-2",
                  {
                    hidden: !session,
                  },
                )}
              >
                {!!session && (
                  <div
                    className={clsx(
                      itemClassName,
                      "flex-col border-b border-b-gray-700 !py-3 hover:bg-inherit",
                    )}
                  >
                    <div className={clsx("font-medium")}>
                      Signed in as {session.user.firstName}
                    </div>
                    <div className={clsx("flex items-center")}>
                      {!!organisation && (
                        <_OrganisationWrapper
                          organisation={organisation}
                          className={clsx(
                            "block whitespace-nowrap overflow-hidden overflow-ellipsis",
                          )}
                        >
                          {organisation.title}
                        </_OrganisationWrapper>
                      )}
                      {session.user.isAdminForOrganisation && (
                        <div className={clsx("ml-2")}>
                          <Link
                            href="/dashboard"
                            className={clsx(
                              "text-sm text-gray-950 bg-gray-100 rounded-full px-1.5 py-0.5 hover:bg-gray-200",
                            )}
                          >
                            Admin
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div>
                  <div className={clsx("flex flex-col py-1.5 pb-2")}>
                    {!!session && (
                      <SecondaryNavigation
                        items={accountNavigationItems}
                        className={clsx("")}
                        itemClassName={clsx(
                          "px-4 group-hover:opacity-75 group-hover:hover:opacity-100 hover:bg-gray-800 py-3",
                        )}
                        iconClassName={clsx("stroke-[1.5] h-4 w-4")}
                        onItemClick={close}
                      />
                    )}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        );
      }}
    </Popover>
  );
};

const _OrganisationWrapper = ({ children, organisation, className }) => {
  const url = getSiteUrl(organisation);

  if (organisation.hasSite && !!organisation.slug) {
    return (
      <a href={url} className={clsx(className)}>
        {children}
      </a>
    );
  }

  return <div className={clsx(className)}>{children}</div>;
};

const _RouterHandler = ({ close }) => {
  const router = useRouter();

  // When the route changes, we need to close the popover
  // This is because the popover is rendered outside of the
  // router context
  useEffect(() => {
    router.events.on("routeChangeComplete", close);

    return () => {
      router.events.off("routeChangeComplete", close);
    };
  }, [router.events, close]);

  return null;
};

export default AuthMenuPopover;
