import {
  Calendar,
  FileText,
  Home,
  Lock,
  LogIn,
  LogOut,
  PieChart,
  User,
} from "react-feather";

// Set the navigation items
const navigationItems = [
  {
    key: "account",
    href: "/account",
    icon: Home,
    label: "Your Account",
  },
  {
    key: "sessions",
    href: "/account/sessions",
    icon: Calendar,
    label: "Sessions",
  },
  {
    key: "resources",
    href: "/account/resources",
    icon: FileText,
    label: "Recent Resources",
  },
  {
    key: "pathways",
    href: "/account/pathways",
    icon: PieChart,
    label: "Pathway Enrolments",
  },
  {
    key: "profile",
    href: "/account/profile",
    icon: User,
    label: "Profile",
  },
  {
    key: "password",
    href: "/account/password",
    icon: Lock,
    label: "Password",
  },
  {
    key: "signout",
    href: "/auth/signout",
    icon: LogOut,
    label: "Sign out",
  },
];

export default navigationItems;
